export const links = [
    {
        id: 1,
        text: 'service',
        url: '#services',
    },
    {
        id: 2,
        text: 'videossubhead',
        url: '#video',
    },
    {
        id: 3,
        text: 'whychooseus',
        url: '#whyChoose',
    },
    {
        id: 4,
        text: 'contactus',
        url: '#contact',
    },
];

export const bankLogo = [
    {
        id: 1,
        img: require('../assets/img/bank/tele.jpg'),
        // linke:'',
        link: 'https://www.youtube.com/watch?v=lVvcKrn3GmQ&t=16s',
    },
    {
        id: 2,
        img: require('../assets/img/bank/togo.png'),
        // linke:'',
        link: 'https://www.togofirst.com/fr/tic/1005-7808-a-la-decouverte-de-trankyl-l-appli-togolaise-de-mise-en-relation-entre-prestataires-a-domicile-et-particuliers',
    },
    {
        id: 3,
        img: require('../assets/img/bank/lome.png'),
        // linke: 'https://semoa-group.com/semoa-group/wp-content/uploads/2022/04/Semoa-logo1.png',
        link: 'https://lomebougeinfo.tg/togo-decouvrez-trankyl-la-seule-application-pour-tous-les-services-2/',
    },
    {
        id: 4,
        img: require('../assets/img/bank/cio.png'),
        // linke: '',
        link: 'https://cio-mag.com/trankyl-lapplication-pour-tous-les-services-en-toute-tranquillite/',
    },
    {
        id: 5,
        img: require('../assets/img/bank/ocean.jpg'),
        // linke:'',
        link: 'https://oceans-news.com/plateforme-numerique-trankyl-solution-sure-de-mise-en-relation/',
    },
    {
        id: 5,
        img: require('../assets/img/bank/africa-presse.png'),
        // linke:'',
        link: 'https://www.africapresse.paris/Sati-SAI-CEO-de-Trankyl-au-Togo-Nous-voulons-nous-positionner-sur-le-marche-des',
    },
];
