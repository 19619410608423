import React, {useEffect, useState} from 'react';
import logo from '../../assets/img/logo/logo.svg';
import ReactFlagsSelect from 'react-flags-select';
import i18next from 'i18next';
import ReactCountryFlag from 'react-country-flag';
import {links} from '../../layouts/Data';
import axios from 'axios';
import DropdownComp from '../global/Dropdown';

const handleClick = e => {
    e.preventDefault();
    const target = e.target.getAttribute('href');
    const location = document.querySelector(target).offsetTop;

    window.scrollTo({
        left: 0,
        top: location - 120,
    });
};

export default function Header({
    t,
    countries,
    selectedCountry,
    setSelectedCountry,
    setActiveCity,
    setServiceType,
    serviceType,
}) {
    const [select, setSelect] = useState('FR');
    const [codes, setCodes] = useState();
    const [customLabels, setCustomLabels] = useState();
    const onSelect = code => {
        setSelect(code);
        switch (code) {
            case 'FR':
                i18next.changeLanguage('fr');
                break;
            case 'GB':
                i18next.changeLanguage('en');
                break;
            case 'HU':
                i18next.changeLanguage('hu');
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        axios.get('https://new-api.trankyl.me/api/get-systemlanguage').then(data => {
            const arr = data.data.data;
            const codes = arr.map(el => {
                if (el.system_lang_code === 'en') {
                    return 'GB';
                }
                return el.system_lang_code.toUpperCase();
            });
            const customLabels = codes.reduce((acc, el) => {
                if (el === 'GB') {
                    acc['GB'] = 'EN';
                } else {
                    acc[el] = el;
                }
                return acc;
            }, {});
            setCodes(codes);
            setCustomLabels(customLabels);
        });
    }, []);

    return (
        <>
            {/* <Topbar/> */}
            <header>
                <section className="py-4">
                    <div className="naviagtion naviagtion-white fixed-top transition">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-light p-0">
                                <a className="navbar-brand p-0" href="#">
                                    <img src={logo} style={{width: '163px'}} />
                                    {/* <img src="assets/images/logo/1.png" alt="Logo" width="163px"/> */}
                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navigation"
                                    aria-controls="navigation"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse text-center" id="navigation">
                                    <ul className="navbar-nav mx-auto">
                                        {links.map(link => {
                                            return (
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link text-dark text-capitalize"
                                                        href={link.url}
                                                        key={link.id}
                                                        onClick={handleClick}>
                                                        {t(link.text)}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                        {/* <li class="nav-item dropdown">
                      <a
                        class="nav-link text-dark text-capitalize dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <ReactCountryFlag
                          style={{ marginRight: 10 }}
                          width={60}
                          height={50}
                          // countryCode={selectedCountry.cust_serv_code}
                          svg
                        />
                        {selectedCountry.cust_serv_country}
                      </a>
                      <div class="dropdown-menu">
                        {countries &&
                          countries.map((count) => (
                            <>
                              <p
                                onClick={() => {
                                  setSelectedCountry(count);
                                }}
                                class="dropdown-item text-color"
                                style={{cursor:"pointer"}}
                              >
                                <ReactCountryFlag
                                  style={{ marginRight: 10,cursor:"pointer" }}
                                  width={60}
                                  height={50}
                                  // countryCode={count.cust_serv_code}
                                  svg
                                />
                                {count.cust_serv_country}
                              </p>
                            </>
                          ))}
                      </div>
                    </li> */}
                                    </ul>
                                    {/* <DropdownComp
                    countries={countries}
                    setServiceType={setServiceType}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    setActiveCity={setActiveCity}
                    serviceType={serviceType}
                    t={t}
                  /> */}
                                    <span className="language-text nav-link text-dark text-capitalize">
                                        {t('Language')}
                                    </span>

                                    <ReactFlagsSelect
                                        className="menu-flags"
                                        onSelect={onSelect}
                                        countries={codes}
                                        selected={select}
                                        customLabels={customLabels}
                                    />

                                    <br />

                                    {/* <button onClick={()=>{
              var scrollDiv = document.getElementById("loan").offsetTop;
              window.scrollTo({ top: scrollDiv - 50, behavior: 'smooth'});
            }} className="btn btn-outline-primary text-white ml-3">Apply Loan</button> */}

                                    {/* <Link to="/privacy-policy">Policy</Link> */}
                                </div>
                            </nav>
                        </div>
                    </div>
                </section>
            </header>
        </>
    );
}
