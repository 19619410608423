import {Spin} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';

export default function SelectVendor({
    activeCity,
    currentCountry,
    subCatId,
    serviceType,
    districtId,
    categoryId,
    next,
    setVendorId,
}) {
    // eslint-disable-next-line no-undef
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState('');

    useEffect(() => {
        setLoading('vendors');
        axios
            .post('https://new-api.trankyl.me/api/vendors/search', {
                service_type: serviceType,
                country_id: serviceType == '1' ? currentCountry?.country_id : '000',
                city_id: serviceType == '1' ? activeCity?.city_id : '00',
                district_id: serviceType == '1' ? districtId : '',
                sub_cat_id: subCatId,
                // category_id: categoryId
            })
            .then(res => {
                setLoading('');

                setVendors(res.data.data);
            })
            .catch(err => {
                console.log(err, 'BANNER ERROR');
            });
    }, [currentCountry, subCatId, activeCity]);
    return (
        <div className="vendor-cont" style={{marginTop: 0}}>
            {loading === 'vendors' && <Spin style={{margin: 'auto', width: '100%', marginTop: 20}} />}
            <div className="dis">
                {loading !== 'vendors' &&
                    vendors?.map(obj => {
                        return (
                            <div
                                className="card-e"
                                onClick={() => {
                                    setVendorId(obj.vendor_id);
                                    next();
                                }}>
                                <div className="icon-e">
                                    <img className="img" src={obj.vendor_image} />
                                </div>

                                <div className="p">
                                    <div className="p-a"> {obj.vendor_name}</div>
                                    <div className="p-b">{obj.vendor_bio !== 'null' && obj.vendor_bio} </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
