import i18next from 'i18next';
import React, {useState} from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1 !important',
    },
};
const VideoSection = ({t}) => {
    const [youtube, setYoutube] = useState();
    const [open, setOpen] = useState();
    const videoDescription = t('video_description')?.split('-retour-a-la-ligne-');
    const videoSteps = t('video1_steps')?.split(',');
    return (
        <section class="wide-40">
            <div class="container" id="video">
                <div class="row">
                    <div class="col-12 text-center">
                        <p class="subtitle">{t('videoshead')}</p>
                        <h2 class="section-title">{t('videossubhead')}</h2>
                    </div>
                    <div class="col-md-6 pr-lg-4 mb-4 mb-md-0">
                        <div class="position-relative aos-init aos-animate" data-aos="fade-right">
                            {/* <img src="assets/images/about/final-about.png" class="img-fluid rounded-sm" alt="video-bg"/> */}
                            <div class="  position-relative rounded-sm">
                                <img
                                    src="assets/images/video/first.png"
                                    class="img-fluid rounded-sm"
                                    alt="video-thumb"
                                />

                                <span
                                    class="venobox play-icon icon-center vbox-item"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        if (i18next.language == 'fr') {
                                            setYoutube('https://www.youtube.com/embed/m3fS0_pEKFI');
                                        } else {
                                            setYoutube('https://www.youtube.com/embed/Jg52pECIeU8');
                                        }

                                        setOpen(true);
                                    }}>
                                    <i class="fa fa-play  bg-black rounded-circle"></i>
                                </span>
                            </div>
                            <div>
                                <h3
                                    className=" text-center "
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                    style={{height: '100px', marginTop: '30px'}}>
                                    {t('video_heading')}
                                </h3>
                                {videoDescription?.map(obj => (
                                    <p>{obj}</p>
                                ))}
                                <p>
                                    <ul class="vs">
                                        {videoSteps?.map(obj => (
                                            <li>{obj}</li>
                                        ))}
                                    </ul>
                                </p>
                                <ul class="list-inline mt-4">
                                    <li class="list-inline-item mb-3 mb-lg-0">
                                        <a
                                            class="btn btn-primary aos-init aos-animate"
                                            data-aos="zoom-in"
                                            data-aos-delay="400"
                                            href="https://play.google.com/store/apps/details?id=com.trankyl.vhero&hl=en_IN&gl=US">
                                            <img
                                                src="assets/images/icon/001-google-play.png"
                                                class="img-fluid mr-2"
                                                alt=""
                                            />
                                            Google Play
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a
                                            class="btn btn-primary aos-init aos-animate"
                                            href="https://apps.apple.com/us/app/trankyl-heros/id1548459730"
                                            data-aos="zoom-in"
                                            data-aos-delay="400">
                                            <i class="fa fa-apple mr-2"></i> app store
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 pr-lg-4 mb-4 mb-md-0">
                        <div class="position-relative aos-init aos-animate" data-aos="fade-right">
                            {/* <img src="assets/images/about/final-about.png" class="img-fluid rounded-sm" alt="video-bg"/> */}
                            <div class=" position-relative rounded-sm">
                                <img
                                    src="assets/images/video/second.png"
                                    class="img-fluid rounded-sm"
                                    alt="video-thumb"
                                />

                                <span
                                    class="venobox play-icon icon-center vbox-item"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        setYoutube('https://www.youtube.com/embed/iNyqPHv3HEI');

                                        setOpen(true);
                                    }}>
                                    <i class="fa fa-play  bg-black rounded-circle"></i>
                                </span>
                            </div>
                            <div>
                                <h3
                                    className=" text-center "
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                    style={{height: '100px', marginTop: '30px'}}>
                                    {t('video_heading2')}
                                </h3>
                                <p>{t('video_description2')}</p>
                                <ul class="list-inline mt-4">
                                    <li class="list-inline-item mb-lg-0 pointer-btn">
                                        <a
                                            class="btn btn-primary aos-init aos-animate"
                                            href="/proposer-mes-services"
                                            data-aos="zoom-in"
                                            data-aos-delay="500">
                                            <svg
                                                style={{color: 'white'}}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                viewBox="0 0 448 512">
                                                <path d="M128 40c0-22.1 17.9-40 40-40s40 17.9 40 40V188.2c8.5-7.6 19.7-12.2 32-12.2c20.6 0 38.2 13 45 31.2c8.8-9.3 21.2-15.2 35-15.2c25.3 0 46 19.5 47.9 44.3c8.5-7.7 19.8-12.3 32.1-12.3c26.5 0 48 21.5 48 48v48 16 48c0 70.7-57.3 128-128 128l-16 0H240l-.1 0h-5.2c-5 0-9.9-.3-14.7-1c-55.3-5.6-106.2-34-140-79L8 336c-13.3-17.7-9.7-42.7 8-56s42.7-9.7 56 8l56 74.7V40zM240 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z" />
                                            </svg>
                                            <span style={{marginLeft: '10px'}}>{t('click_here')}</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul class="list-inline">
                                    <li class="list-inline-item mb-3 mb-lg-0">
                                        <a
                                            class="btn btn-primary aos-init aos-animate"
                                            data-aos="zoom-in"
                                            data-aos-delay="400"
                                            href="https://play.google.com/store/apps/details?id=com.trankyl.vhero&hl=en_IN&gl=US">
                                            <img
                                                src="assets/images/icon/001-google-play.png"
                                                class="img-fluid mr-2"
                                                alt=""
                                            />
                                            Google Play
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a
                                            class="btn btn-primary aos-init aos-animate"
                                            href="https://apps.apple.com/us/app/trankyl-heros/id1548459730"
                                            data-aos="zoom-in"
                                            data-aos-delay="400">
                                            <i class="fa fa-apple mr-2"></i> app store
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                onRequestClose={() => {
                    setOpen(false);
                }}
                shouldCloseOnOverlayClick={true}
                isOpen={open}
                style={customStyles}
                contentLabel="Example Modal">
                <iframe
                    width="560"
                    height="315"
                    src={youtube}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </Modal>
        </section>
    );
};

export default VideoSection;
