import React, {useLayoutEffect, useState} from 'react';
import {HashRouter, Routes, Route} from 'react-router-dom';
import Home from '../src/screens/pages/home/Home';
import About from '../src/screens/pages/about/About';
import Contact from '../src/screens/pages/contact/Contact';
import Privacy from '../src/screens/privacy-policy.js';
import Terms from '../src/screens/terms-condition.js';
import {useEffect} from 'react';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import axios from 'axios';

// const root = ReactDOM.createRoot(
//   document.getElementById("root")
// );

function App() {
    const [langData, setLangData] = useState(false);
    useEffect(() => {
        axios
            .get('https://new-api.trankyl.me/api/get-language')
            .then(({data: {data}}) => {
                const fr = data.reduce((results, item) => {
                    results[item.lang_key] = item.lang_value_fr;
                    return results;
                }, {});
                const en = data.reduce((results, item) => {
                    results[item.lang_key] = item.lang_value_en;
                    return results;
                }, {});
                const hu = data.reduce((results, item) => {
                    results[item.lang_key] = item.lang_value_hu;
                    return results;
                }, {});
                i18n.init({
                    resources: {
                        fr: {
                            translation: fr,
                        },
                        en: {
                            translation: en,
                        },
                        hu: {translation: hu},
                    },
                }).then(() => {
                    // setTimeout(() => {
                    setLangData(true);
                    // }, 1000);
                });
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home langData={langData} />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="privacy-policy" element={<Privacy />} />
                <Route path="terms-conditions" element={<Terms />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
