import React from 'react';
import {Link} from 'react-router-dom';

export default function About() {
    return (
        <>
            <h4>About</h4>
            <nav>
                <Link to="/contact">Contact</Link>
            </nav>
        </>
    );
}
