import React from 'react';

const WhyChooseSection = ({t}) => {
    return (
        <section style={{paddingTop: 0}} className="position-relative Choose mt-5" id="whyChoose">
            <div className="container">
                <div className="row ">
                    <div class="col-12 text-center">
                        <h2 class="section-title aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            {t('valuessubhead')}?
                        </h2>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="card border-0 shadow rounded-xs pt-5">
                                <div className="card-body">
                                    {/* <div className="">§</div> */}
                                    <i className="fa fa-users icon-lg icon-yellow icon-bg-yellow icon-bg-circle "></i>
                                    <h4 className="mt-4 mb-3">{t('human')}</h4>
                                    <p className="para">{t('humantext')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="card border-0 shadow rounded-xs pt-5">
                                <div className="card-body">
                                    <i className="fa fa-globe icon-lg icon-blue icon-bg-blue icon-bg-circle "></i>
                                    <h4 className="mt-4 mb-3">{t('respect')}</h4>
                                    <p className="para">{t('respecttext')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="card border-0 shadow rounded-xs pt-5">
                                <div className="card-body">
                                    <i className="fa fa-line-chart icon-lg icon-orange icon-bg-orange icon-bg-circle "></i>
                                    <h4 className="mt-4 mb-3">{t('quality')}</h4>
                                    <p className="para">{t('qualitytext')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="card border-0 shadow rounded-xs pt-5">
                                <div className="card-body">
                                    <i className="fa fa-smile-o icon-lg icon-green icon-bg-green icon-bg-circle "></i>
                                    <h4 className="mt-4 mb-3">{t('satisfaction')}</h4>
                                    <p className="para">{t('satisfactiontext')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="card border-0 shadow rounded-xs pt-5">
                                <div className="card-body">
                                    <i className="fa fa-object-ungroup icon-lg icon-primary icon-bg-primary icon-bg-circle "></i>
                                    <h4 className="mt-4 mb-3">{t('transparency')}</h4>
                                    <p className="para"> {t('transparencytext')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right">
                            <div className="card border-0 shadow rounded-xs pt-5">
                                <div className="card-body">
                                    <i className="fa fa-handshake-o icon-lg icon-cyan  icon-bg-cyan  icon-bg-circle "></i>
                                    <h4 className="mt-4 mb-3">{t('trust')}</h4>
                                    <p className="para">{t('trusttext')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseSection;
