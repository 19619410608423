import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import 'reactjs-popup/dist/index.css';
import {useTranslation} from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Banner from '../../../components/Home/Banner';
import Footer from '../../../components/Home/Footer';
import Header from '../../../components/Home/Header';
import Services from '../../../components/Home/Services';
import DownloadApp from '../../../components/Home/DownloadApp';
import VideoSection from '../../../components/Home/VideoSection';
import TalkAboutSection from '../../../components/Home/TalkAboutSection';
import WhyChooseSection from '../../../components/Home/WhyChooseSection';
import About from '../../../components/Home/About';
import Contact from '../../../components/Home/Contact';
import LocationSection from '../../../components/Home/LocationSection';
// import News from '../../../components/Home/News';
import TheyTalkAboutUs from '../../../components/Home/TheyTalkAboutUs';
import Stats from '../../../components/Home/Stats';
import CustomerReviews from '../../../components/Home/CustomerReviews';
import {ConfigProvider} from 'antd';
import PaymentsMethods from '../../../components/Home/PaymentMethods';

const theme = {
    token: {colorTextBase: '#000', colorPrimary: '#fec601'},
    components: {
        Button: {
            primaryColor: 'rgb(0, 0, 0)',
        },
        Steps: {
            colorTextLightSolid: 'rgb(0, 0, 0)',
        },
    },
};

export default function Home({langData}) {
    const {t} = useTranslation();
    const [banner, setBanner] = React.useState([]);
    const [activeCity, setActiveCity] = useState();
    const [serviceType, setServiceType] = useState('1');
    const [countries, setCountries] = React.useState([]);
    const [currentCountry, setCurrentCountry] = useState();
    const [selectedCountry, setSelectedCountry] = React.useState();

    const getBanner = () => {
        axios
            .get('https://new-api.trankyl.me/api/fetch-slider-by-lang')
            .then(res => {
                setBanner(res.data.data);
            })
            .catch(err => {});
    };

    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Name is required';
        }
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        if (!values.phone) {
            errors.phone = 'Phone number is required';
        }
        if (!values.salary) {
            errors.salary = 'Salary is required';
        }

        if (!values.address) {
            errors.address = 'Address is required';
        }
        return errors;
    };

    const getCountries = () => {
        Promise.allSettled([
            axios.get('https://new-api.trankyl.me/api/get-country-contact'),
            axios.post('https://new-api.trankyl.me/api/countries/search', {is_active: true}),
            axios.get('https://new-api.trankyl.me/api/get-all-countries'),
        ])
            .then(results => {
                const contactRes = results[0].status === 'fulfilled' ? results[0].value : null;
                const coRes = results[1].status === 'fulfilled' ? results[1].value : null;
                const servicesCountries = coRes.data.data.filter(obj => obj.country_has_service === '1');

                const countries = contactRes.data.data;
                countries.push({
                    ID: 6,
                    cust_serv_address: '',
                    cust_serv_city: 'Budapest',
                    cust_serv_code: 'HU',
                    cust_serv_country: 'Hungary',
                    cust_serv_country_code: 36,
                    cust_serv_fb: 'https://www.facebook.com/trankyl',
                    cust_serv_insta: 'https://www.instagram.com/trankyl_me/',
                    cust_serv_mail: 'info@trankyl.me',
                    cust_serv_map: 'https://goo.gl/maps/qJSFSGDfHZTk7R9y9',
                    cust_serv_name: 'Service clients Hungary',
                    cust_serv_tel_1: 706845054,
                    cust_serv_tel_2: 0,
                    cust_serv_tiktok: 'tiktok.com/@trankyl_me',
                });

                const temp = countries.reduce((acc, el) => {
                    acc[el.cust_serv_country_code] = el;
                    return acc;
                }, {});
                const temp2 = servicesCountries.map(el => {
                    return {
                        ...temp[+el.country_calling_codes],
                        label: el.country_name,
                        value: el.country_id,
                        id: el.country_id,
                        ...el,
                    };
                });
                const togo = temp2.filter(obj => obj.country_id == '217');

                setCountries(temp2);
                setCurrentCountry(togo[0]);

                // setSelectedCountry(togo[0]);
            })
            .catch(error => {});
    };

    const feedbackhandleSubmit = (val, sub) => {};

    useEffect(() => {
        if (selectedCountry) {
            const temp = countries.filter(el => el.country_id == selectedCountry.key);
            setCurrentCountry(temp[0]);
        }
    }, [selectedCountry, countries]);

    useEffect(() => {
        AOS.init();
        window.scrollTo({top: 0, behavior: 'smooth'});
        getBanner();
        getCountries();
    }, []);

    if (!langData) {
        return (
            <div className="loading-suspense">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <ConfigProvider theme={theme}>
            <Header
                setSelectedCountry={setSelectedCountry}
                selectedCountry={currentCountry}
                countries={countries}
                serviceType={serviceType}
                setActiveCity={setActiveCity}
                setServiceType={setServiceType}
                t={t}
            />
            <ToastContainer />
            <Banner banner={banner} t={t} />
            <TheyTalkAboutUs />
            <Stats t={t} />
            <Services
                serviceType={serviceType}
                countries={countries}
                currentCountry={currentCountry}
                setServiceType={setServiceType}
                setSelectedCountry={setSelectedCountry}
                setActiveCity={setActiveCity}
                activeCity={activeCity}
                t={t}
            />
            <DownloadApp t={t} />
            <CustomerReviews t={t} />
            <VideoSection t={t} />
            <WhyChooseSection t={t} />
            <TalkAboutSection t={t} />
            <PaymentsMethods t={t} />
            <About t={t} />
            {/* <News banner={banner} t={t}/> */}
            <Contact
                t={t}
                validate={validate}
                selectedCountry={currentCountry}
                feedbackhandleSubmit={feedbackhandleSubmit}
            />
            <LocationSection selectedCountry={currentCountry} />
            <Footer selectedCountry={currentCountry} t={t} />
        </ConfigProvider>
    );
}
