import React, {useEffect, useState} from 'react';
import axios from 'axios';
import i18next from 'i18next';
// import Modal from "react-modal";
import {Formik} from 'formik';
import Swal from 'sweetalert2';
import {Accordion} from 'react-bootstrap';
import {AiFillCaretRight} from 'react-icons/ai';
import Step from '../global/Step';
import {Modal, Spin} from 'antd';
import {act} from 'react-dom/test-utils';
import DropdownC from '../global/Dropdown';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1 !important',
    },
};
const initialValues = {
    we_name: '',
    we_phone: '',
    we_days: '',
    we_message: '',
    we_created_date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
    we_subcategory: '',
};
const Services = ({
    t,
    serviceType,
    countries,
    setServiceType,
    setSelectedCountry,
    setActiveCity,
    currentCountry,
    activeCity,
}) => {
    const [category, setCategory] = React.useState();
    const [categoryId, setCatId] = useState('');
    const [subCatId, setSubCatId] = useState();
    const [subcategory, setSubCategory] = React.useState([]);
    const [loading, setLoading] = useState('');
    const [lo2, setLo2] = useState(false);
    const [lo, setLo] = useState(currentCountry);
    const [refetch, setR] = useState('');
    const [modalVsib, setModalVisible] = React.useState(false);

    useEffect(() => {
        setLoading('countries');
        if (currentCountry) {
            axios
                .post('https://new-api.trankyl.me/api/categories/search', {
                    service_type: serviceType,
                    country_id: serviceType == '1' ? currentCountry?.country_id : '000',
                    city_id: serviceType == '1' ? activeCity?.city_id : '00',
                })
                .then(res => {
                    setLoading('');
                    setLo2(false);
                    setCategory(res.data.data);
                    setCatId(res.data.data[0].cat_id);
                });
        }
    }, [activeCity, serviceType]);

    useEffect(() => {
        if (currentCountry !== lo) {
            setLo(currentCountry);
            setLo2(true);
        }
    }, [currentCountry, lo]);

    useEffect(() => {
        setLoading('sub');
        axios
            .post('https://new-api.trankyl.me/api/sub-categories/search', {
                service_type: serviceType,
                country_id: serviceType == '1' ? currentCountry?.country_id : '000',
                city_id: serviceType == '1' ? activeCity?.city_id : '00',
                category_id: categoryId,
            })
            .then(res => {
                setLoading('');
                setSubCategory(res.data.data);
            });
    }, [categoryId, refetch, activeCity, serviceType]);

    return (
        <div id="services">
            <section id="tabs-2" className="wide-40 tabs-section division">
                <div className="container">
                    <div className="row">
                        <div class="col-12 text-center">
                            <h2 className="section-title" data-aos="fade-up" data-aos-delay="200">
                                {t('serviceshead')}
                            </h2>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="400">
                                {t('servicessubhead')}
                            </p>
                            <div className="">
                                <DropdownC
                                    countries={countries}
                                    setServiceType={setServiceType}
                                    selectedCountry={currentCountry}
                                    setSelectedCountry={setSelectedCountry}
                                    setActiveCity={setActiveCity}
                                    serviceType={serviceType}
                                    t={t}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 bg-light">
                            <div id="tabs-nav" className="list-group text-center clearfix">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    {loading === 'categories' && <Spin style={{margin: 'auto', marginTop: 20}} />}
                                    {category &&
                                        category.map(e => {
                                            return (
                                                <li
                                                    onClick={() => {
                                                        setSubCategory(e.subcat);
                                                        setCatId(e.cat_id);
                                                    }}
                                                    key={e.cat_image}
                                                    className="nav-item icon-xs">
                                                    <a
                                                        className={`nav-link ${e.cat_id == categoryId && 'active'}`}
                                                        id={e.cat_isactive}
                                                        data-toggle="pill"
                                                        href={e.cat_isactive}
                                                        role="tab"
                                                        aria-controls="tab-11"
                                                        aria-selected="true">
                                                        {/* {e.cat_image} */}
                                                        <img
                                                            width={50}
                                                            height="auto"
                                                            src={`https://new-api.trankyl.me/${e.cat_image}`}
                                                            alt={e[`cat_name_${i18next.language}`]}
                                                        />
                                                        &nbsp; {e[`cat_name_${i18next.language}`]}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-9">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="tab-11"
                                    role="tabpanel"
                                    aria-labelledby="tab11-list">
                                    <section class="">
                                        {loading === 'sub' && (
                                            <Spin style={{margin: 'auto', width: '100%', marginTop: 20}} />
                                        )}
                                        <div class="container">
                                            <div class="row">
                                                {subcategory &&
                                                    subcategory.map(res => (
                                                        <div
                                                            class="col-lg-4 col-sm-6 mb-5 mt-4 mb-lg-0 team-member d-flex"
                                                            data-aos="fade-up"
                                                            data-aos-delay="00"
                                                            onClick={() => {
                                                                setSubCatId(res.subcat_id);

                                                                setModalVisible(true);
                                                            }}>
                                                            <div class="text-center shadow card py-3 border-0 rounded-sm mt-10 flex-container">
                                                                <div class="card-body column">
                                                                    {/* <i class="fa fa-desktop icon-lg icon-bg-square mb-5 icon-primary"></i> */}
                                                                    <div style={{position: 'relative'}}>
                                                                        <img
                                                                            width={'100%'}
                                                                            height={150}
                                                                            src={`https://new-api.trankyl.me/${res.subcat_image}`}
                                                                            alt={res[`subcat_name_${i18next.language}`]}
                                                                        />
                                                                        <h4
                                                                            class="mb-3 text-white text-align-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                bottom: 0,
                                                                                alignSelf: 'center',
                                                                                width: '100%',
                                                                            }}>
                                                                            {res[`subcat_name_${i18next.language}`]}
                                                                        </h4>
                                                                    </div>
                                                                    <p style={{marginTop: 40}}>
                                                                        {res[`subcat_name_${i18next.language}`]}
                                                                    </p>
                                                                </div>
                                                                <div class="team-member-content p-4">
                                                                    <h5 class="team-member-name text-dark p-3">
                                                                        {res[`subcat_name_${i18next.language}`]}
                                                                    </h5>
                                                                    <h5
                                                                        style={{cursor: 'pointer'}}
                                                                        class="team-member-name text-dark border pe-auto border-dark p-3">
                                                                        {t('enquiry_now')}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="serviceaccordion mt-4">
                    <div className="services-container">
                        <div class="col-12 text-center">
                            <h2 className="section-title" data-aos="fade-up" data-aos-delay="200">
                                {t('serviceshead')}
                            </h2>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="400">
                                {t('servicessubhead')}
                            </p>
                            <div className="mb-4">
                                <DropdownC
                                    countries={countries}
                                    setServiceType={setServiceType}
                                    selectedCountry={currentCountry}
                                    setSelectedCountry={setSelectedCountry}
                                    setActiveCity={setActiveCity}
                                    serviceType={serviceType}
                                    t={t}
                                />
                                {lo2 && <Spin style={{margin: 'auto', width: '100%', marginTop: 20}} />}
                            </div>
                        </div>
                        <Accordion defaultActiveKey="">
                            {category &&
                                category.map((value, index) => {
                                    return (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>
                                                <div
                                                    onClick={() => {
                                                        setR(value.cat_id);
                                                        setCatId(value.cat_id);
                                                        setSubCategory([]);
                                                    }}
                                                    className="list-group-item-accordion d-flex w-100 justify-content-between align-items-center">
                                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center px-4">
                                                            <img
                                                                width={50}
                                                                height="auto"
                                                                src={`https://new-api.trankyl.me/${value.cat_image}`}
                                                                alt={value[`cat_name_${i18next.language}`]}
                                                            />
                                                            &nbsp;{' '}
                                                            <sapn className="mb-1 w-100">
                                                                {value[`cat_name_${i18next.language}`]}
                                                            </sapn>
                                                        </div>
                                                    </div>
                                                    <AiFillCaretRight size={25} style={{justifyContent: 'flex-end'}} />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="servicecard-container">
                                                    {loading === 'sub' && (
                                                        <Spin
                                                            style={{
                                                                margin: 'auto',
                                                                width: '100%',
                                                                marginTop: 20,
                                                                marginBottom: 20,
                                                            }}
                                                        />
                                                    )}
                                                    {subcategory &&
                                                        subcategory.map(res => (
                                                            <div
                                                                class="col-lg-4 col-sm-6 mb-5 mt-4 mb-lg-0 team-member d-flex"
                                                                data-aos="fade-up"
                                                                data-aos-delay="00"
                                                                onClick={() => {
                                                                    setSubCatId(res.subcat_id);
                                                                    setModalVisible(true);
                                                                }}>
                                                                <div class="text-center shadow card py-3 border-0 rounded-sm mt-10 flex-container">
                                                                    <div class="card-body column">
                                                                        <div style={{position: 'relative'}}>
                                                                            <img
                                                                                width={'100%'}
                                                                                height={150}
                                                                                src={`https://new-api.trankyl.me/${res.subcat_image}`}
                                                                                alt={
                                                                                    res[
                                                                                        `subcat_name_${i18next.language}`
                                                                                    ]
                                                                                }
                                                                            />
                                                                            <h4
                                                                                class="mb-3 text-white text-align-center"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    bottom: 0,
                                                                                    alignSelf: 'center',
                                                                                    width: '100%',
                                                                                }}>
                                                                                {res[`subcat_name_${i18next.language}`]}
                                                                            </h4>
                                                                        </div>
                                                                        <p style={{marginTop: 40}}>
                                                                            {res[`subcat_name_${i18next.language}`]}
                                                                        </p>
                                                                    </div>
                                                                    <div class="team-member-content p-4">
                                                                        <h5 class="team-member-name text-dark p-3">
                                                                            {res[`subcat_name_${i18next.language}`]}
                                                                        </h5>
                                                                        <h5 class="team-member-name text-dark border border-dark p-3">
                                                                            {t('enquiry_now')}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                        </Accordion>
                    </div>
                </div>
            </section>

            <Modal
                open={modalVsib}
                onCancel={() => setModalVisible(false)}
                centered
                destroyOnClose
                footer={null}
                width={700}
                contentLabel="Example Modal">
                <div className="step-cont">
                    <Step
                        serviceType={serviceType}
                        currentCountry={currentCountry}
                        activeCity={activeCity}
                        t={t}
                        setModal={setModalVisible}
                        closeModal={!modalVsib}
                        subCatId={subCatId}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Services;
