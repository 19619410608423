import {Spin} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';

export default function SelectService({
    activeCity,
    currentCountry,
    subCatId,
    serviceType,
    districtId,
    vendorId,
    setService,
    next,
}) {
    // eslint-disable-next-line no-undef
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState('');

    useEffect(() => {
        setLoading('services');
        axios
            .post('https://new-api.trankyl.me/api/services/search', {
                service_type: serviceType,
                country_id: serviceType == '1' ? currentCountry?.country_id : '000',
                city_id: serviceType == '1' ? activeCity?.city_id : '00',
                district_id: serviceType == '1' ? districtId : '',
                vendor_id: vendorId,
            })
            .then(res => {
                setLoading('');

                setServices(res.data.data);
            })
            .catch(err => {
                console.log(err, 'BANNER ERROR');
            });
    }, [currentCountry, subCatId, activeCity]);

    return (
        <div className="serv-cont" style={{marginTop: 0}}>
            {loading === 'services' && <Spin style={{margin: 'auto', width: '100%', marginTop: 20}} />}
            <div className="dis">
                {services?.map(obj => {
                    return (
                        <div
                            className="card-e"
                            style={{width: '100%'}}
                            onClick={() => {
                                setService(obj);
                                next();
                            }}>
                            <div className="p">
                                <div className="p-a"> {obj.s_title}</div>
                                <div className="p-b" style={{textAlign: ''}}>
                                    {obj.s_des !== 'null' && obj.s_des}{' '}
                                </div>
                            </div>
                            <div
                                className="p-b"
                                style={{textAlign: 'right', color: 'blue', minWidth: '90px', fontWeight: 600}}>
                                {obj.s_min_price !== 'null' && obj.s_min_price} F CFA
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
