import axios from 'axios';
import {Formik} from 'formik';
import React from 'react';
import Swal from 'sweetalert2';
import Slider from 'react-slick';

function Contact({t, selectedCountry}) {
    const [isSubmitting, setsubmitting] = React.useState(false);
    const [initialValues, feedbackValues] = React.useState({
        name: '',
        email: '',
        phone: '',
        object: '',
        message: '',
    });
    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!values.phone) {
            errors.phone = 'Required';
        }
        if (!values.object) {
            errors.object = 'Required';
        }
        return errors;
    };
    const handleSubmit = (values, setSubmitting) => {
        axios
            .post('https://new-api.trankyl.me/api/contactUs/store', {
                email: values.email,
                name: values.name,
                phone: values.phone,
                title: values.object,
                message: values.message,
            })
            .then(response => {
                if (response.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Thank you for contacting us. we will reach out soon.',
                        icon: 'success',
                        confirmButtonText: 'ok',
                    });
                    setTimeout(() => window.location.reload(), 3000);
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Could not process your request now. Try again later.',
                        icon: 'error',
                        confirmButtonText: 'ok',
                    });
                    setTimeout(() => window.location.reload(), 3000);
                }
            });
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const testimonials = [
        {
            id: '1',
            desp: `J’ai contacté l’entreprise Trankil après avoir cherché en vain du personnel de maison qualifié.
      Je suis agréablement surpris. Cette structure est probablement la meilleure que j’ai eu à rencontrer. Elle propose des ressources humaines de qualité, elle est professionnelle, à l’écoute,  et se souci du travail bien fait.
      Voilà quelques semaines que nous avons fait appel à leurs services. RAS ! 😊 tout se passe pour le mieux.
      Je recommande fortement`,
            name: 'Tassoua Yann',
        },
        {
            id: '2',
            desp: `J’ai testé les services pour avoir une aide à domicile. C’était professionnel, efficace et franchement satisfaction à 1000%. Je recommande !!!`,
            name: 'Mouniratou BARRO',
        },
        {
            id: '3',
            desp: `L’application Trankyl est vraiment ingénieuse. Et cela facilitera la tâche à bon nombre de personnes. En outre, elle est simple d’utilisation et très fluide. It is an user-friendly app.`,
            name: 'Komi Sokou',
        },
        {
            id: '4',
            desp: `Avec tranky vous avez toute votre satisfaction, que se soit c
      Recherche d'emploi ou recherche des employés,ils sont super`,
            name: 'Brigitte AKASSi',
        },
        {
            id: '5',
            desp: `Professional & good quality!
      \n
      (Traduit par Google)
      Professionnel & bonne qualité !`,
            name: 'Ben Andoh',
        },
    ];
    return (
        <>
            <section class="section bg-light py-5">
                <div class="container" id="contact">
                    <div class="row">
                        <div className="col-12 col-lg-8">
                            <div class="col-12 text-center">
                                <h2 class="section-title mb-1">{t('contactshead')}</h2>
                                <p class="subtitle mb-4">{t('contactssubhead')}</p>
                            </div>
                            <div class="form ">
                                <Formik
                                    initialValues={initialValues}
                                    validate={values => validate(values)}
                                    onSubmit={(values, {setSubmitting}) => {
                                        handleSubmit(values, setSubmitting);
                                    }}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form className="row mt-5" onSubmit={handleSubmit}>
                                            <div class="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    class="form-control mb-1"
                                                    placeholder={t('name')}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                />
                                                <label for="amount">
                                                    <span style={{color: 'red', marginLeft: 20}}>
                                                        {errors.name && touched.name && errors.name}
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    class="form-control mb-1"
                                                    placeholder={t('email')}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                <label for="amount">
                                                    <span style={{color: 'red', marginLeft: 20}}>
                                                        {errors.email && touched.email && errors.email}
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    class="form-control mb-1"
                                                    placeholder={t('phone')}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                />
                                                <label for="amount">
                                                    <span style={{color: 'red', marginLeft: 20}}>
                                                        {errors.phone && touched.phone && errors.phone}
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="object"
                                                    class="form-control mb-1"
                                                    placeholder={t('object')}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.object}
                                                />
                                                <label for="amount">
                                                    <span style={{color: 'red', marginLeft: 20}}>
                                                        {errors.object && touched.object && errors.object}
                                                    </span>
                                                </label>
                                            </div>

                                            <div class="col-12">
                                                <textarea
                                                    name="message"
                                                    class="form-control mb-1"
                                                    placeholder={t('message')}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}></textarea>
                                                <label for="amount">
                                                    <span style={{color: 'red', marginLeft: 20}}>
                                                        {errors.message && touched.message && errors.message}
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="button-submit">
                                                <button type="submit" class="btn btn-primary">
                                                    {t('send')}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        {/* <div className="col-12 col-lg-5">
              <Slider className="testi-slide" {...settings}>
                {testimonials.map((bankLogo) => {
                  return (
                    <div
                      className="card px-4 py-5 border-0 rounded-lg 
                     text-center card-border-bottom mb-5 mt-5"
                      style={{ borderColor: "#ffc527" }}
                    >
                      <i
                        className="fa fa-quote-right icon-quote mb-1 mx-auto"
                        style={{ color: "#ffc527" }}
                      ></i>
                      <p className="mb-4">{bankLogo.desp}</p>
                      <h4>{bankLogo.name}</h4>
                      <span className="h6">Happy client</span>
                    </div>
                  );
                })}
              </Slider>
            </div> */}
                        <div class="col-12 col-lg-4">
                            <div class="p-5 rounded-xs shadow">
                                <ul class="list-unstyled">
                                    <li class="d-flex mb-1" data-aos="fade-up" data-aos-delay="100">
                                        <i class="fa fa-map-o icon-primary"></i>
                                        <div class="pl-3">
                                            <h6 class="text-dark">Address</h6>
                                            <ul class="list-unstyled">
                                                <li>
                                                    {selectedCountry?.cust_serv_name},{selectedCountry?.cust_serv_city}-
                                                    {selectedCountry?.cust_serv_country}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="d-flex mb-4" data-aos="fade-up" data-aos-delay="200">
                                        <i class="fa fa-envelope-o icon-primary"></i>
                                        <div class="pl-3">
                                            <h6 class="text-dark">Email</h6>
                                            <ul class="list-unstyled">
                                                <li>{selectedCountry?.cust_serv_mail}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="d-flex mb-4" data-aos="fade-up" data-aos-delay="300">
                                        <i class="fa fa-phone icon-primary"></i>
                                        <div class="pl-3">
                                            <h6 class="text-dark">Mobile</h6>
                                            <ul class="list-unstyled">
                                                <li>
                                                    +{selectedCountry?.cust_serv_country_code}{' '}
                                                    {selectedCountry?.cust_serv_tel_1}
                                                </li>
                                                {selectedCountry?.cust_serv_tel_2 != '0' && (
                                                    <>
                                                        <li>
                                                            {'+'}
                                                            {selectedCountry?.cust_serv_country_code}{' '}
                                                            {selectedCountry?.cust_serv_tel_2}
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
