import React, {useEffect, useState} from 'react';
import {Button, Flex, message, Steps, theme} from 'antd';
import {Formik} from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import FormContent from './Form';
import SelectDistrict from './SelectDistrict';
import SelectVendor from './SelectVendor';
import SelectService from './SelectService';
import {useForm} from 'antd/lib/form/Form';
const Step = ({t, serviceType, currentCountry, setModal, activeCity, subCatId, closeModal}) => {
    const {token} = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [scategory, selectedCategory] = React.useState('');
    const [districtId, setDistrictId] = useState('');
    const [service, setService] = useState();
    const [formLoading, setFormLoading] = useState(false);
    const [form] = useForm();
    const [vendorId, setVendorId] = useState('');
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const initialValues = {
        we_name: '',
        we_phone: '',
        we_days: '',
        we_message: '',
        we_created_date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
        we_subcategory: '',
    };
    const contentStyle = {
        textAlign: 'center',
        color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px ${token.colorBorder}`,
        marginTop: 16,
        // padding: 10
    };
    const validate = values => {
        const errors = {};
        if (!values.we_name) {
            errors.we_name = 'Required';
        }
        if (!values.we_phone) {
            errors.we_phone = 'Required';
        }
        if (!values.we_days) {
            errors.we_days = 'Required';
        }
        if (!values.we_message) {
            errors.we_message = 'Required';
        }
        return errors;
    };
    const handleSubmit = (values, setSubmitting) => {
        form.submit();
        // values.we_subcategory = scategory;
        // axios
        //   .post('https://new-api.trankyl.me/api/enquiry/store', values)
        //   .then((response) => {
        //     console.log(values);
        //     if (response.status == 200) {
        //       Swal.fire({
        //         title: 'Success',
        //         text: 'Thank you for contacting us. we will reach out soon.',
        //         icon: 'success',
        //         confirmButtonText: 'ok',
        //       });
        //       setTimeout(() => window.location.reload(), 3000);
        //     } else {
        //       Swal.fire({
        //         title: 'Error',
        //         text: 'Could not process your request now. Try again later.',
        //         icon: 'error',
        //         confirmButtonText: 'ok',
        //       });
        //       setTimeout(() => window.location.reload(), 3000);
        //     }
        //   });
    };
    const stepsAtplace = [
        {
            title: t('select_district'),
            content: (
                <SelectDistrict
                    activeCity={activeCity}
                    currentCountry={currentCountry}
                    subCatId={subCatId}
                    setDistrictId={setDistrictId}
                    next={next}
                />
            ),
        },
        {
            title: t('choose_vendor'),
            content: (
                <SelectVendor
                    activeCity={activeCity}
                    currentCountry={currentCountry}
                    subCatId={subCatId}
                    serviceType={serviceType}
                    districtId={districtId}
                    // categoryId={categoryId}
                    setVendorId={setVendorId}
                    next={next}
                />
            ),
        },
        {
            title: t('select_service'),
            content: (
                <SelectService
                    activeCity={activeCity}
                    currentCountry={currentCountry}
                    subCatId={subCatId}
                    serviceType={serviceType}
                    districtId={districtId}
                    vendorId={vendorId}
                    setService={setService}
                    next={next}
                />
            ),
        },
        {
            title: t('book'),
            content: <FormContent form={form} setFormLoading={setFormLoading} setModal={setModal} />,
        },
    ];
    const stepsOnline = [
        // {
        //   title: 'Select District',
        //   content: (
        //     <SelectDistrict
        //       activeCity={activeCity}
        //       currentCountry={currentCountry}
        //       subCatId={subCatId}
        //       setDistrictId={setDistrictId}
        //       next={next}
        //     />
        //   ),
        // },
        {
            title: t('choose_vendor'),
            content: (
                <SelectVendor
                    activeCity={activeCity}
                    currentCountry={currentCountry}
                    subCatId={subCatId}
                    serviceType={serviceType}
                    districtId={districtId}
                    setVendorId={setVendorId}
                    next={next}
                />
            ),
        },
        {
            title: t('select_service'),
            content: (
                <SelectService
                    activeCity={activeCity}
                    currentCountry={currentCountry}
                    subCatId={subCatId}
                    serviceType={serviceType}
                    districtId={districtId}
                    vendorId={vendorId}
                    setService={setService}
                    next={next}
                />
            ),
        },
        {
            title: t('book'),
            content: (
                <FormContent form={form} setFormLoading={setFormLoading} setModal={setModal} />
                // <Formik
                //   initialValues={initialValues}
                //   validate={(values) => validate(values)}
                //   onSubmit={(values, { setSubmitting }) => {
                //     handleSubmit(values, setSubmitting);
                //   }}
                // >
                //   {({
                //     values,
                //     errors,
                //     touched,
                //     handleChange,
                //     handleBlur,
                //     handleSubmit,
                //     isSubmitting,
                //   }) => (
                //     <form
                //       className="row mt-15 mobile-form"
                //       style={{ width: 500 }}
                //       onSubmit={handleSubmit}
                //     >
                //       <div class="col-12 text-center">
                //         <h2 class="section-title mb-1">{t('contactshead')}</h2>
                //         <p class="subtitle mb-4">{t('contactssubhead')}</p>
                //       </div>
                //       <div class="col-lg-6">
                //         <input
                //           type="text"
                //           name="we_name"
                //           class="form-control"
                //           placeholder={t('name')}
                //           onChange={handleChange}
                //           onBlur={handleBlur}
                //           value={values.we_name}
                //         />
                //         <label for="amount">
                //           <span style={{ color: 'red', marginLeft: 20 }}>
                //             {errors.we_name && touched.we_name && errors.we_name}
                //           </span>
                //         </label>
                //       </div>

                //       <div class="col-lg-6">
                //         <input
                //           type="number"
                //           name="we_phone"
                //           class="form-control"
                //           placeholder={t('phone')}
                //           onChange={handleChange}
                //           onBlur={handleBlur}
                //           value={values.we_phone}
                //         />
                //         <label for="amount">
                //           <span style={{ color: 'red', marginLeft: 20 }}>
                //             {errors.we_phone && touched.we_phone && errors.we_phone}
                //           </span>
                //         </label>
                //       </div>
                //       <div class="col-lg-6">
                //         <input
                //           type="number"
                //           name="we_days"
                //           class="form-control"
                //           placeholder={t('Number of days')}
                //           onChange={handleChange}
                //           onBlur={handleBlur}
                //           value={values.we_days}
                //         />
                //         <label for="amount">
                //           <span style={{ color: 'red', marginLeft: 20 }}>
                //             {errors.we_days && touched.we_days && errors.we_days}
                //           </span>
                //         </label>
                //       </div>
                //       <div class="col-lg-6">
                //         <input
                //           type="text"
                //           name="we1_days"
                //           class="form-control"
                //           placeholder={t('category')}
                //           disabled
                //           value={scategory}
                //         />
                //         <label for="amount"></label>
                //       </div>

                //       <div class="col-12">
                //         <textarea
                //           name="we_message"
                //           class="form-control"
                //           placeholder={t('message')}
                //           onChange={handleChange}
                //           onBlur={handleBlur}
                //           value={values.we_message}
                //         ></textarea>
                //         <label for="amount">
                //           <span style={{ color: 'red', marginLeft: 20 }}>
                //             {errors.we_message &&
                //               touched.we_message &&
                //               errors.we_message}
                //           </span>
                //         </label>
                //       </div>
                //       <div class="col-12">
                //         {/* <button type="submit" class="btn btn-primary">
                //           {t('send')}
                //         </button> */}
                //       </div>
                //     </form>
                //   )}
                // </Formik>
            ),
        },
    ];
    let steps;
    if (serviceType == '1') {
        steps = stepsAtplace;
    } else {
        steps = stepsOnline;
    }

    const items = steps.map(item => ({
        key: item.title,
        title: item.title,
    }));

    const [itemsValue, setItems] = useState(items);

    useEffect(() => {
        if (closeModal) {
            setItems([]);
        }
    }, [closeModal]);

    return (
        <>
            <Steps current={current} items={itemsValue} />
            <div style={contentStyle} className="step-content-style">
                {steps[current].content}
            </div>
            <div
                style={{
                    marginTop: 24,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                {/* {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )} */}

                {current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}>
                        {t('previous')}
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button loading={formLoading} type="primary" onClick={() => handleSubmit()}>
                        {t('submit')}
                    </Button>
                )}
            </div>
        </>
    );
};
export default Step;
