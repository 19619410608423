import i18next from 'i18next';
import React from 'react';
import {Carousel} from 'react-responsive-carousel';

const Banner = ({t, banner: data}) => {
    const banner = data.filter(obj => obj.banner_isactive != 0 && obj.banner_type === 'website');
    banner.sort((a, b) => {
        if (a.priority < b.priority) {
            return 1;
        } else if (a.priority > b.priority) {
            return -1;
        } else {
            return 0;
        }
    });
    return (
        <Carousel infiniteLoop={true} showThumbs={false} autoPlay={true} interval={5000}>
            {banner &&
                banner.map(bann => {
                    const temp = t(bann.sub_title)?.split('-retour-a-la-ligne-');
                    return (
                        <div>
                            <img src={`https://new-api.trankyl.me/${bann.banner_img}`} />
                            <div className="bannerHeading">
                                <h4 className="text-white position-relative">
                                    {' '}
                                    <div style={{textTransform: 'none'}} class="B">
                                        {t(bann.title)}
                                    </div>{' '}
                                </h4>
                                <p className="text-white ">
                                    {temp?.map(str => (
                                        <div>{str}</div>
                                    ))}
                                </p>
                                <ul className="list-inline">
                                    <li className="list-inline-item mx-1 my-2">
                                        {bann.button_title && (
                                            <div>
                                                {bann.banner_isactive == 2 ? (
                                                    <button
                                                        onClick={() => {
                                                            var scrollDiv =
                                                                document.getElementById('DownloadApp').offsetTop;
                                                            window.scrollTo({
                                                                top: scrollDiv - 50,
                                                                behavior: 'smooth',
                                                            });
                                                        }}
                                                        className="btn btn-primary">
                                                        {t(bann.button_title)}
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-primary banner_link">
                                                        <a
                                                            href={bann.banner_link}
                                                            target="_blank"
                                                            style={{textDecoration: 'initial'}}>
                                                            {t(bann.button_title)}
                                                        </a>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    );
                })}
        </Carousel>
    );
};

export default Banner;
