import React from 'react';
import logo from '../../assets/img/logo/logo-wh.svg';
import {FaTiktok} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
import i18next from 'i18next';

export default function Footer({style, t, selectedCountry}) {
    return (
        <>
            <footer style={style} className="bg-secondary">
                <section className="wide-40 border-bottom border-color">
                    <div className="container">
                        <div className="row justify-content-between text-center">
                            <div className="col-md-12 mb-4 mb-md-0">
                                <img src={logo} style={{width: '163px'}} />
                                <p className="text-light my-4" style={{margin: 'auto'}}>
                                    {t('about')}
                                </p>
                                <ul className="list-inline social-icons">
                                    <li className="list-inline-item">
                                        <a href={selectedCountry?.cust_serv_fb} target="_blank">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={selectedCountry?.cust_serv_tiktok} target="_blank">
                                            <i className="fa">
                                                <FaTiktok />
                                            </i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            href=" https://www.youtube.com/channel/UCCUy3EP1vzY-gGLIZvWeYgA"
                                            target="_blank">
                                            <i className="fa fa-youtube"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={selectedCountry?.cust_serv_insta} target="_blank">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <a href={`tel:+${selectedCountry?.cust_serv_country_code}${selectedCountry?.cust_serv_tel_1}`}>
                        <div className="stickyPhone">
                            <FaPhoneAlt className="icons" color="#fff" size={20} />
                        </div>
                    </a>
                </section>

                <section className="py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6  col-lg-4 text-center text-md-left mb-3 mb-md-0">
                                <p className="mb-0 text-light">
                                    Copyright &copy;
                                    <script>
                                        var CurrentYear = new Date().getFullYear() document.write(CurrentYear)
                                    </script>
                                    {t('rights')} <a href="#"> Trankyl</a>
                                </p>
                            </div>
                            <div className="col-md-6  col-lg-8 text-md-right text-center">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a
                                            target="_blank"
                                            href={
                                                i18next.language == 'fr'
                                                    ? 'terms-conditions/fr.html'
                                                    : 'terms-conditions/en.html'
                                            }
                                            className="text-light">
                                            {t('terms')}
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        | &nbsp;
                                        <a
                                            target="_blank"
                                            href={
                                                i18next.language == 'fr'
                                                    ? 'privacy-policy/fr.html'
                                                    : 'privacy-policy/en.html'
                                            }
                                            className="text-light">
                                            {t('privacy')}
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        | &nbsp;
                                        <a target="_blank" href="/delete-my-account" className="text-danger">
                                            {t('deleteAccount')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </>
    );
}
