import React from 'react';
import Slider from 'react-slick';

const CustomerReviews = ({t}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        // dots: false,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const testimonials = [
        // {
        //   id: '1',
        //   desp: `J’ai contacté l’entreprise Trankyl après avoir cherché en vain du personnel de maison qualifié.
        // Je suis agréablement surpris. Cette structure est probablement la meilleure que j’ai eu à rencontrer. Elle propose des ressources humaines de qualité, elle est professionnelle, à l’écoute,  et se souci du travail bien fait.
        // Voilà quelques semaines que nous avons fait appel à leurs services. RAS ! 😊 tout se passe pour le mieux.
        // Je recommande fortement`,
        //   name: 'Tassoua Yann',
        // },
        {
            id: '1',
            desp: `Je suis vraiment ravis du service de votre entreprise. Je recommande cette structure si tu veux obtenir un service professionnel au top niveau`,
            name: 'Komlan folly',
        },
        {
            id: '2',
            desp: `J’ai testé les services pour avoir une aide à domicile. C’était professionnel, efficace et franchement satisfaction à 1000%. Je recommande !!!`,
            name: 'Mouniratou BARRO',
        },
        // {
        //   id: '3',
        //   desp: `L’application Trankyl est vraiment ingénieuse. Et cela facilitera la tâche à bon nombre de personnes. En outre, elle est simple d’utilisation et très fluide. It is an user-friendly app.`,
        //   name: 'Komi Sokou',
        // },
        {
            id: '4',
            desp: `Avec Trankyl vous avez toute votre satisfaction, que se soit la recherche d'emploi ou la recherche de prestataire, ils sont supers`,
            name: 'Brigitte AKASSi',
        },
        {
            id: '5',
            desp: `Professionnel & bonne qualité !`,
            name: 'Ben Andoh',
        },
        {
            id: '6',
            desp: `Si tu veux etre tranquille, choisit Trankyl. Top et professionnel. Je recommande vivement !!!`,
            name: 'Rachel Mensah',
        },
    ];
    return (
        <div className="customer-reviews">
            <h2 className="section-title">{t('customers_review')}</h2>
            <Slider className="testi-slide" arrows={true} {...settings}>
                {testimonials.map(bankLogo => {
                    return (
                        <div
                            className="card px-4 py-5 border-0 rounded-lg 
                     text-center card-border-bottom mb-5 "
                            style={{borderColor: '#ffc527'}}>
                            <i className="fa fa-quote-right icon-quote mb-4 mx-auto" style={{color: '#ffc527'}}></i>
                            <p className="mb-4">{bankLogo.desp}</p>
                            <h4>{bankLogo.name}</h4>
                            <span className="h6">Happy client</span>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default CustomerReviews;
