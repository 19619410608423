import React from 'react';

const LocationSection = ({selectedCountry}) => {
    return (
        <div className=""></div>
        // <div className="">
        //   {selectedCountry?.callingcodes == '228' && (
        //     <section class="section map-list">
        //       <div class="container">
        //         <div class="row">
        //           <div
        //             class="col-lg-12 rounded-xs mobile-height mb-5 mb-lg-0"
        //             id="map_canvas"
        //             data-latitude="51.507351"
        //             data-longitude="-0.127758"
        //             data-marker="images/marker.png"
        //           >
        //             <iframe
        //               src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.4407316357!2d1.165811!3d6.205449000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x95715ea56673f710!2sTrankyl!5e0!3m2!1sen!2sin!4v1676117746413!5m2!1sen!2sin"
        //               width="100%"
        //               height="300"
        //               style={{ border: 0 }}
        //               allowFullScreen=""
        //               loading="lazy"
        //               referrerPolicy="no-referrer-when-downgrade"
        //               title=" "
        //             ></iframe>
        //           </div>
        //           {/* <div class="col-lg-4">
        //             <div class="p-5 rounded-xs shadow">
        //               <ul class="list-unstyled">
        //                 <li
        //                   class="d-flex mb-4"
        //                   data-aos="fade-up"
        //                   data-aos-delay="100"
        //                 >
        //                   <i class="fa fa-map-o icon-primary"></i>
        //                   <div class="pl-3">
        //                     <h6 class="text-dark">Address</h6>
        //                     <ul class="list-unstyled">
        //                       <li>
        //                         {selectedCountry.cust_serv_name},
        //                         {selectedCountry.cust_serv_city}-
        //                         {selectedCountry.cust_serv_country}
        //                       </li>
        //                     </ul>
        //                   </div>
        //                 </li>
        //                 <li
        //                   class="d-flex mb-4"
        //                   data-aos="fade-up"
        //                   data-aos-delay="200"
        //                 >
        //                   <i class="fa fa-envelope-o icon-primary"></i>
        //                   <div class="pl-3">
        //                     <h6 class="text-dark">Email</h6>
        //                     <ul class="list-unstyled">
        //                       <li>{selectedCountry.cust_serv_mail}</li>
        //                     </ul>
        //                   </div>
        //                 </li>
        //                 <li
        //                   class="d-flex mb-4"
        //                   data-aos="fade-up"
        //                   data-aos-delay="300"
        //                 >
        //                   <i class="fa fa-phone icon-primary"></i>
        //                   <div class="pl-3">
        //                     <h6 class="text-dark">Mobile</h6>
        //                     <ul class="list-unstyled">
        //                       <li>
        //                         +{selectedCountry.cust_serv_country_code}{' '}
        //                         {selectedCountry.cust_serv_tel_1}
        //                       </li>
        //                       {selectedCountry.cust_serv_tel_2 != '0' && (
        //                         <>
        //                           <li>
        //                             {'+'}
        //                             {selectedCountry.cust_serv_country_code}{' '}
        //                             {selectedCountry.cust_serv_tel_2}
        //                           </li>
        //                         </>
        //                       )}
        //                     </ul>
        //                   </div>
        //                 </li>
        //               </ul>
        //             </div>
        //           </div> */}
        //         </div>
        //       </div>
        //     </section>
        //   )}
        // </div>
    );
};

export default LocationSection;
