import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import React from 'react';
import {Carousel} from 'react-bootstrap';
import {bankLogo} from '../../layouts/Data2';

export default function TheyTalkAboutUs() {
    return (
        <div className="splide2">
            <Splide
                options={{
                    type: 'loop',
                    autoplay: true,
                    perPage: 4,
                    interval: 1500,
                    lazyLoad: 'nearby',
                    pagination: false,
                    arrows: false,
                    breakpoints: {
                        2040: {
                            perPage: 5,
                            // height: '200px',
                        },
                        600: {
                            perPage: 3,
                        },
                    },
                    rewind: true,
                    focus: 'center',
                }}>
                {bankLogo.map(bankLogo => {
                    return (
                        <SplideSlide>
                            <div className="px-3 mb-3 mt-4">
                                <div className="card border-0  rounded-xs">
                                    <a href={bankLogo.link} target="_blank" rel="noreferrer">
                                        {' '}
                                        <img src={bankLogo.img} className="img-fluid card-img-top" alt="post-thumb" />
                                    </a>
                                </div>
                            </div>
                        </SplideSlide>
                    );
                })}
                {/* <SplideSlide>
          <div style={styles.splide}>
            <img
              alt=""
              src="https://d33wubrfki0l68.cloudfront.net/b6bd1c05d1dd3c7d0ec88497d991e7ecab28fe53/2eb7b/images/forbes.png"
              style={styles.image}
            />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div style={styles.splide}>
            <img
              alt=""
              src="https://d33wubrfki0l68.cloudfront.net/b6bd1c05d1dd3c7d0ec88497d991e7ecab28fe53/2eb7b/images/forbes.png"
              style={styles.image}
            />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div style={styles.splide}>
            <img
              alt=""
              src="https://d33wubrfki0l68.cloudfront.net/b6bd1c05d1dd3c7d0ec88497d991e7ecab28fe53/2eb7b/images/forbes.png"
              style={styles.image}
            />
          </div>
        </SplideSlide> */}
            </Splide>
        </div>
    );
}

const styles = {
    carousel: {
        width: '100%',
        // height: '200px',
        borderWidth: '2px',
        backgroudColor: 'gray',
    },
    splide: {
        display: 'flex',
    },
    image: {
        margin: 'auto',
        borderWidth: '1px',
        width: '90%',
        // minHeight: '150px',
        maxWidth: '150px',
    },
};
