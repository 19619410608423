import React from 'react';

export default function Stats({t}) {
    return (
        <div>
            <div className="stats">
                <div className="stats-section">
                    <div className="section-number">{t('stats_downloads_value')}+</div>
                    <div className="section-des">{t('stats_downloads')}</div>
                </div>
                <div className="stats-section">
                    <div className="section-number">{t('stats_vendors_value')}+</div>
                    <div className="section-des">{t('stats_vendors')}</div>
                </div>
                <div className="stats-section">
                    <div className="section-number">{t('stats_services_value')}+</div>
                    <div className="section-des">{t('stats_services')}</div>
                </div>
                <div className="stats-section">
                    <div className="section-number">{t('stats_bookings_value')}+</div>
                    <div className="section-des">{t('stats_bookings')}</div>
                </div>
                {/* <div className="stats-section">
          <div className="section-number">{t('stats')}+</div>
          <div className="section-des">{t('stats_downloads')}</div>
        </div> */}
            </div>
            <div className="stats-mobile">
                <div className="stats-m">
                    <div className="stats-section">
                        <div className="section-number">{t('stats_downloads_value')}+</div>
                        <div className="section-des">{t('stats_downloads')}</div>
                    </div>
                    <div className="stats-section">
                        <div className="section-number">{t('stats_vendors_value')}+</div>
                        <div className="section-des">{t('stats_vendors')}</div>
                    </div>
                </div>
                <div className="stats-m">
                    <div className="stats-section">
                        <div className="section-number">{t('stats_services_value')}+</div>
                        <div className="section-des">{t('stats_services')}</div>
                    </div>
                    <div className="stats-section">
                        <div className="section-number">{t('stats_bookings_value')}+</div>
                        <div className="section-des">{t('stats_bookings')}</div>
                    </div>
                    {/* <div className="stats-section">
            <div className="section-number">{t('stats')}</div>
            <div className="section-des">{t('stats_downloads')}</div>
          </div> */}
                </div>
            </div>
            {/* <div className=""></div> */}
        </div>
    );
}
