import {Spin} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';

export default function SelectDistrict({activeCity, currentCountry, subCatId, setDistrictId, next}) {
    const [districts, setDistricts] = useState([]);
    const [loading, setLoading] = useState('');
    useEffect(() => {
        setLoading('districts');
        axios
            .post('https://new-api.trankyl.me/api/districts/search', {
                service_type: '1',
                country_id: currentCountry?.country_id,
                city_id: activeCity?.city_id,
                sub_cat_id: subCatId,
            })
            .then(res => {
                setLoading('');

                setDistricts(res.data.data);
            })
            .catch(err => {
                console.log(err, 'BANNER ERROR');
            });
    }, [currentCountry, subCatId, activeCity]);
    return (
        <div className="">
            {loading === 'districts' && <Spin style={{margin: 'auto', width: '100%', marginTop: 20}} />}
            <div className="dis-cont" style={{marginTop: 0}}>
                {districts.map(obj => {
                    return (
                        <div
                            className="dis"
                            onClick={() => {
                                setDistrictId(obj.district_id);
                                next();
                            }}>
                            <div className="card-e">
                                <div className="icon-e">
                                    <i class="fa-solid fa-location-dot"></i>
                                </div>
                                <div className="p">
                                    <div className="p-a">{obj.district_name}</div>
                                    {/* <div className="p-b">Nombre de heroes disponible : 25 </div> */}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
