import {Dropdown, Select, Space} from 'antd';
import React, {useState, useEffect} from 'react';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
// Countries.js

function Countries({onSelect, countries, t, selectedCountry}) {
    // Récupérer les pays depuis le réseau

    const handleChange = (country, obj, t) => {
        onSelect(obj);
    };

    return (
        <Select onChange={handleChange} defaultValue={'217'} style={{minWidth: '100px', maxWidth: '108px'}}>
            {countries?.map(c => (
                <Select.Option key={c.id}>
                    <div className="" style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            style={{width: '20px', height: '20px', marginRight: 10}}
                            src={`https://flagcdn.com/80x60/${c?.country_code?.toLowerCase() || 'tg'}.png`}
                            alt="Test"
                        />

                        {/* <img src='https://flagcdn.com/80x60/${item.country_code.toLowerCase()}.png' alt='Test'/> */}
                        <div className="">{c.label}</div>
                    </div>
                </Select.Option>
            ))}
        </Select>
    );
}

function ServiceType({onSelect, setServiceType, t}) {
    const [serviceTypes, setServiceType2] = useState([
        {name: t('online'), id: '0'},
        {name: t('at_place'), id: '1'},
    ]);

    // Récupérer les pays depuis le réseau
    useEffect(() => {
        setServiceType2([
            {name: t('online'), id: '0'},
            {name: t('at_place'), id: '1'},
        ]);
    }, [t]);

    return (
        <Select defaultValue={'1'} onChange={country => setServiceType(country)}>
            {serviceTypes.map(c => (
                <Select.Option key={c.id}>{c.name}</Select.Option>
            ))}
        </Select>
    );
}

// Cities.js

function Cities({country, onSelect, setActiveCity}) {
    const [cities, setCities] = useState(country?.cities);
    const [city, setCity] = useState(country?.cities[0]);

    // const fetchCities = async (country) => {
    //   // appeler l'API des villes du pays
    //   return Promise.resolve([
    //     { name: 'Paris', id: '1' },
    //     { name: 'Marseille', id: '2' },
    //     { name: 'Lyon', id: '3' },
    //   ]);
    // };
    // useEffect(() => {
    //   if (country) {
    //     fetchCities(country.id).then((cities) => {
    //       setCities(cities);
    //     });
    //   }
    // }, [country]);

    useEffect(() => {
        setActiveCity(city);
    }, [city]);

    useEffect(() => {
        setCities(country?.cities);
        setCity(country?.cities[0]);
    }, [country]);
    return (
        <Select
            value={city?.city_name}
            onChange={city => {
                const cityObj = cities.filter(obj => obj.city_id == city);
                setCity(cityObj[0]);
            }}>
            {cities?.map(c => (
                <Select.Option key={c.city_id}>{c.city_name}</Select.Option>
            ))}
        </Select>
    );
}

// App.js

export default function DropdownC({
    countries,
    selectedCountry,
    setSelectedCountry,
    setActiveCity,
    setServiceType,
    serviceType,
    t,
}) {
    return (
        <div className="country-selection">
            <ServiceType setServiceType={setServiceType} t={t} />
            {serviceType == '1' && (
                <>
                    <Countries onSelect={setSelectedCountry} countries={countries} />
                    <Cities country={selectedCountry} setActiveCity={setActiveCity} />
                </>
            )}
        </div>
    );
}
