import React, {useState} from 'react';
import {Button, Checkbox, Form, Input, InputNumber, message} from 'antd';
import axios from 'axios';
const onFinishFailed = errorInfo => {};
const FormContent = ({form, setFormLoading, setModal}) => {
    const [loading, setLoading] = useState();

    const onFinish = values => {
        setFormLoading(true);
        axios
            .post('https://new-api.trankyl.me/api/booking-request/store', {
                ...values,
            })
            .then(res => {
                setFormLoading(false);
                message.success('Success');
                setModal(false);
            })
            .catch(err => {
                console.log(err, 'BANNER ERROR');
            });
    };
    return (
        <div className="form-content">
            <Form
                name="basic"
                form={form}
                labelCol={{
                    span: 7,
                }}
                // wrapperCol={{
                //   span: 16,
                // }}
                style={{
                    maxWidth: 400,
                    margin: 'auto',
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Form.Item
                    label="Name"
                    name="client_name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your name!',
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Number"
                    name="client_phone_number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your number!',
                        },
                    ]}>
                    <InputNumber style={{width: '100%'}} width={'100%'} />
                </Form.Item>
                <Form.Item
                    label="Place"
                    name="service_place"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your place!',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Informations"
                    name="additionnal_infos"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your info!',
                        },
                    ]}>
                    <Input.TextArea />
                </Form.Item>
                {/* <Form.Item
      name="remember"
      valuePropName="checked"
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
      >
      <Checkbox>Remember me</Checkbox>
    </Form.Item> */}

                {/* <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    > */}
                {/* <Button type="primary" htmlType="submit">
        Submit
      </Button> */}
                {/* </Form.Item> */}
            </Form>
        </div>
    );
};
export default FormContent;
